import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface MoreMenuItem {
  icon: string;
  name: string;
  value: any;
  show?: boolean;
}

enum ButtonTypeEnum {
  FAB = 'fab',
  ICON = 'icon',
}

@Component({
  selector: 'app-grid-row-more-menu',
  templateUrl: './grid-row-more-menu.component.html',
  styleUrls: ['./grid-row-more-menu.component.scss'],
})
export class GridRowMoreMenuComponent {
  public buttonTypeEnum = ButtonTypeEnum;
  @Input('buttonType') buttonType: ButtonTypeEnum = ButtonTypeEnum.ICON;
  @Input('tooltip') tooltip = 'More';
  @Input('icon') icon = 'more_vert';
  @Input('data') data: MoreMenuItem[] = [];
  @Output('select') select: EventEmitter<MoreMenuItem> =
    new EventEmitter<MoreMenuItem>();
}
