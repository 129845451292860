<button *ngIf="buttonType === buttonTypeEnum.FAB" mat-mini-fab color="primary" [matMenuTriggerFor]="tableMenu"
  [matTooltip]="tooltip">
  <mat-icon>{{icon}}</mat-icon>
</button>
<button *ngIf="buttonType === buttonTypeEnum.ICON" mat-icon-button color="primary" [matMenuTriggerFor]="tableMenu"
  [matTooltip]="tooltip">
  <mat-icon>{{icon}}</mat-icon>
</button>
<mat-menu #tableMenu="matMenu">
  <button mat-menu-item *ngFor="let item of data" (click)="select.emit(item)">
    <mat-icon *ngIf="item?.icon">{{item?.icon}}</mat-icon>
    <span>{{item?.name}}</span>
  </button>
</mat-menu>
