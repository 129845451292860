<div class="toast-content">
  <div class="toast-strip" [style.backgroundColor]="color"></div>
  <div class="toast-icon">
    <img [src]="icon">
  </div>
  <div class="toast-details">
    <div class="title">{{header}}</div>
    <div class="message">{{data.message}}</div>
    <img matRipple class="action" (click)="onDismiss()" src="assets/toast-and-dialog/close-icon.svg">
  </div>
</div>
