/**
 * Encrypt JSON Object
 * @param jsonObject 
 * @returns 
 */
export function encriptJSON(jsonObject: any) {
    return btoa(JSON.stringify(jsonObject) || '');
};

/**
 * Decrypt Encrypted JSON string
 * @param jsonStrig 
 * @returns 
 */
export function decriptJSON(jsonStrig: string) {
    return JSON.parse(atob(jsonStrig || ''));
};