import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ticket-log-timeline',
  templateUrl: './ticket-log-timeline.component.html',
  styleUrls: ['./ticket-log-timeline.component.scss']
})
export class TicketLogTimelineComponent implements OnInit {
  public logHeight: number = 130;
  public lineWidth: number = 5;
  public circleRadious: number = 7.5;
  public cardWidth: number = 270;

  @Input() ticketLog: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
