import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ActionButtonItem {
  icon: string;
  name: string;
  value: any;
  show?: boolean;
}

@Component({
  selector: 'app-grid-row-action-button',
  templateUrl: './grid-row-action-button.component.html',
  styleUrls: ['./grid-row-action-button.component.scss']
})
export class GridRowActionButtonComponent {

  @Input('data') data: ActionButtonItem[] = [];
  @Output('select') select: EventEmitter<ActionButtonItem> =
    new EventEmitter<ActionButtonItem>();

}
