import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AlertDialogComponent, AlertToastComponent } from '../components';
import { AlertIcons, AlertTypes } from '../enums';

interface ToastData {
  header?: string;
  duration?: number;
}

interface DialogData {
  header?: string;
  icon?: AlertIcons,
  okayText?: string;
  cancelText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private _toast: MatSnackBar,
    private _dialog: MatDialog
  ) { }

  /**
   * Toast
   *
   * @param {ToastData} { message, type, header, duration }
   * @return {*}
   * @memberof NotificationService
   */
  public toast(message: string, type: AlertTypes, data?: ToastData): MatSnackBarRef<AlertToastComponent> {
    return this._toast.openFromComponent(AlertToastComponent, {
      data: {
        message,
        type,
        header: data?.header
      },
      duration: data?.duration || 10000
    });
  }

  public dialog(message: string, type: AlertTypes, data?: DialogData): MatDialogRef<AlertDialogComponent> {
    return this._dialog.open(AlertDialogComponent, {
      data: {
        message,
        type,
        header: data?.header,
        icon: data?.icon,
        okayText: data?.okayText,
        cancelText: data?.cancelText
      }
    })
  }
}
