import { Injectable } from '@angular/core';
import { RootPaths, AdminPaths } from '../paths';
import { Router } from '@angular/router';

const AUTH_STORAGE_KEY = 'MDELAXI5V20GVE';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router
  ) { }

  setAuthStorage(data: any) {
    const dataString = JSON.stringify(data);
    localStorage.setItem(AUTH_STORAGE_KEY, dataString);
  }

  removeAuthStorage() {
    localStorage.removeItem(AUTH_STORAGE_KEY);
  }

  get isLoggedIn(): boolean {
    return localStorage.getItem(AUTH_STORAGE_KEY) ? true : false;
  }

  getAuthUser(): any {
    const dataString: any = localStorage.getItem(AUTH_STORAGE_KEY);
    return JSON.parse(dataString);
  }

  navOnLogin() {
    this.router.navigate(['/' + RootPaths.ADMIN + '/' + AdminPaths.DEFAULT]);
  }

  navOnLogout() {
    this.router.navigate(['/' + RootPaths.AUTH], {
      queryParams: {
        redirectUrl: location.pathname
      }
    });
  }
}
