<div class="alert-wrapper">
  <div class="alert-container">
    <div class="alert-icon">
      <img [src]="icon">
    </div>
    <div class="alert-content">
      <div class="title">{{header}}</div>
      <div class="message">{{data.message}}</div>
      <div class="action-buttons">
        <button mat-button *ngIf="showCancel" (click)="onClickClose()">{{cancelText}}</button>
        <button mat-button *ngIf="showOkay" (click)="onClickOk()">{{okayText}}</button>
      </div>
    </div>
  </div>
</div>
