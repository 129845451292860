<mat-tab-group [animationDuration]="0" [(selectedIndex)]="tabIndex">
  <mat-tab label="Item Chart"></mat-tab>
  <mat-tab label="Main Key Account Chart"></mat-tab>
  <mat-tab label="Zonal Chart"></mat-tab>
  <mat-tab label="Fuji Branch Chart"></mat-tab>
</mat-tab-group>
<app-admin-item-chart *ngIf="tabIndex === 0"></app-admin-item-chart>
<app-admin-pie-chart *ngIf="tabIndex === 1"></app-admin-pie-chart>
<app-admin-column-chart *ngIf="tabIndex === 2"></app-admin-column-chart>
<app-admin-column-group-chart *ngIf="tabIndex === 3"></app-admin-column-group-chart>
