import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-grid-row-status',
  templateUrl: './grid-row-status.component.html',
  styleUrls: ['./grid-row-status.component.scss']
})
export class GridRowStatusComponent {

  /**
   * Status - Input
   */
  @Input() status: any;

  /**
   * Is Active
   */
  get isActive(): boolean {
    return this.status && this.status === 'active';
  }

}
