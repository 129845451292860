import { Component, Input } from '@angular/core';

@Component({
  selector: 'dashboard-title-count-card',
  templateUrl: './dashboard-title-count-card.component.html',
  styleUrls: ['./dashboard-title-count-card.component.scss']
})
export class DashboardTitleCountCardComponent {
  @Input('data') data: any;
  @Input('count') count: any;
}
