import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../_services';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private loaderService: LoaderService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const loader = request.urlWithParams.indexOf('load=0') === -1;

    if (!loader) {
      return next.handle(request);
    }

    this.loaderService.increase();
    return next
      .handle(request)
      .pipe(
        // retry(1),
        tap(
          (event: any) => {
            if (event instanceof HttpResponse) {
              this.loaderService.decrease();
              // return event;
            }
          }, (error: any) => {
            if (error instanceof HttpErrorResponse) {
              this.loaderService.decrease();
            } else {
              this.loaderService.decrease();
            }
          }
        )
      );
  }
}
