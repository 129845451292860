import { style } from '@angular/animations';
import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[disabledContainer]'
})
export class DisabledContainerDirective implements OnChanges {

  @Input('disabledContainer') public disabledContainer = false;

  private domElement: any;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.domElement = el.nativeElement;
    this.checkStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkStatus();
  }

  private checkStatus() {
    const requiredStyles: any = {
      'pointer-events': 'none'
    };

    if (this.disabledContainer) {
      Object.keys(requiredStyles).forEach(newStyle => {
        this.renderer.setStyle(
          this.domElement, `${newStyle}`, requiredStyles[newStyle]
        );
      });
    } else {
      Object.keys(requiredStyles).forEach(newStyle => {
        this.renderer.removeStyle(
          this.domElement, `${newStyle}`
        );
      });
    }
  }

}
