import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaterialThemeService, ThemeMode } from 'src/app/shared';
import { ApiAppResponse, ApiService } from 'src/app/_services';

import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
exporting(Highcharts);
exportData(Highcharts);

@Component({
  selector: 'app-admin-pie-chart',
  templateUrl: './admin-pie-chart.component.html',
  styleUrls: ['./admin-pie-chart.component.scss'],
})
export class AdminPieChartComponent implements OnInit, OnDestroy {
  public chartList: any[] = [
    {
      title: 'Main Key Account Wise Tickets Count',
      subtitle: 'Click the columns to view detailed view.',
      method: 'Main_Key_Account_Wise_Ticket_Status',
    },
    {
      title: "Main Key Account Wise Product's Units Count",
      subtitle: 'Click the columns to view detailed view.',
      method: 'Main_Key_Account_Wise_Product_Status',
    },
    {
      title: "Main Key Account Wise Unit Status's Units Count",
      subtitle: 'Click the columns to view detailed view.',
      method: 'Main_Key_Account_Wise_Unit_Status',
    },
  ];
  public selectedTabIndex: number = 0;
  public ticketStatus!: string;
  public productMka!: string;
  public unitStatusMka!: string;

  public data: any[] = [];
  public chart = new Chart({});
  private options: any = {
    chart: {
      type: 'pie',
    },
    credits: {
      enabled: true,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        center: ['50%', '88%'],
        size: '170%',
        startAngle: -100,
        endAngle: 100,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '{point.name} - {point.y}',
        },
        showInLegend: true,
      },
    },
    series: [],
    legend: {
      labelFormat: '{name} <span style="opacity: 0.4">{y}</span>',
    },
  };
  private themeChangeSubscription!: Subscription;
  constructor(
    private apiService: ApiService,
    private themeService: MaterialThemeService
  ) {
    this.themeChangeSubscription = this.themeService
      .themeChangeObservable$()
      .subscribe((mode: ThemeMode) => {
        this.themeService.updateChartTheme(this.chart, mode);
      });
  }

  /**
   * On Init Method
   *
   * @memberof AdminPieChartComponent
   */
  ngOnInit(): void {
    this.chart = new Chart(this.options);
    this.themeService.updateChartTheme(this.chart);
    this.onSelectedTabIndexChange(0);
  }

  /**
   * On Destroy Method
   *
   * @memberof AdminPieChartComponent
   */
  ngOnDestroy() {
    if (this.themeChangeSubscription) {
      this.themeChangeSubscription.unsubscribe();
    }
  }

  /**
   * On Selected Tab Index Change
   *
   * @param {number} index
   * @memberof AdminPieChartComponent
   */
  onSelectedTabIndexChange(index: number) {
    this.selectedTabIndex = index;
    switch (index) {
      case 0:
        this.ticketStatusWiseTicketCount();
        break;

      case 1:
        this.productWiseUnitsCount();
        break;

      case 2:
        this.unitStatusWiseUnitsCount();
        break;

      default:
        break;
    }
  }

  /**
   * Ticket Status Wise Ticket Count
   *
   * @memberof AdminPieChartComponent
   */
  ticketStatusWiseTicketCount() {
    this.apiService
      .adminChart(this.chartList[this.selectedTabIndex].method)
      .subscribe((res: any) => {
        if (res.appresponse === ApiAppResponse.SUCCESS) {
          this.data = res.result || [];
          if (this.data?.length) {
            this.onSelectTicketStatus(this.data[0]?.status);
          }
        }
      });
  }

  /**
   * On Select Ticket Status
   *
   * @param {string} status
   * @return {*}
   * @memberof AdminPieChartComponent
   */
  onSelectTicketStatus(status: string) {
    this.ticketStatus = status;
    const item = this.data.find((s: any) => s.status === status);

    if (!item) {
      return;
    }

    const series: any = {
      name: item?.mka,
      colorByPoint: true,
      data: [],
    };

    const options = Object.assign({}, this.options);
    options.title.text = this.chartList[this.selectedTabIndex].title;
    options.subtitle = {
      text: status,
    };
    series.data = [];
    item.data.forEach((c: any) => {
      series.data.push({ name: c.mka, y: Number(c.count) });
    });
    this.chart = new Chart(options);
    this.themeService.updateChartTheme(this.chart);
    this.chart.removeSeries(0);
    this.chart.addSeries(series, true, true);
  }

  /**
   * Product Wise Units Count
   *
   * @memberof AdminPieChartComponent
   */
  productWiseUnitsCount() {
    this.apiService
      .adminChart(this.chartList[this.selectedTabIndex].method)
      .subscribe((res: any) => {
        if (res.appresponse === ApiAppResponse.SUCCESS) {
          this.data = res.result || [];
          if (this.data?.length) {
            this.onSelectProductMka(this.data[0]?.mka);
          }
        }
      });
  }

  /**
   * On Select Product
   *
   * @param {string} product
   * @return {*}
   * @memberof AdminPieChartComponent
   */
  onSelectProductMka(productMka: string) {
    this.productMka = productMka;
    const item = (this.data || []).find((o: any) => o.mka === productMka);

    if (!item) {
      return;
    }

    const series: any = {
      name: item?.mka,
      colorByPoint: true,
      data: [],
    };
    const options = Object.assign({}, this.options);
    options.title.text = this.chartList[this.selectedTabIndex].title;
    options.subtitle = {
      text: productMka,
    };
    (item?.data || []).forEach((product: any) => {
      series.data.push({ name: product.product, y: Number(product.count) });
    });
    options.series = [series];
    this.chart = new Chart(options);
    this.themeService.updateChartTheme(this.chart);
  }

  /**
   * Unit Status Wise Ticket Count
   *
   * @memberof AdminPieChartComponent
   */
  unitStatusWiseUnitsCount() {
    this.apiService
      .adminChart(this.chartList[this.selectedTabIndex].method)
      .subscribe((res: any) => {
        if (res.appresponse === ApiAppResponse.SUCCESS) {
          this.data = res.result || [];
          if (this.data?.length) {
            this.onSelectUnitStatusMka(this.data[0]?.mka);
          }
        }
      });
  }

  /**
   * On Select Unit Status
   *
   * @param {string} unitStatus
   * @return {*}
   * @memberof AdminPieChartComponent
   */
  onSelectUnitStatusMka(unitStatusMka: string) {
    this.unitStatusMka = unitStatusMka;
    const item = (this.data || []).find((o: any) => o.mka === unitStatusMka);

    if (!item) {
      return;
    }

    const series: any = {
      name: item?.mka,
      colorByPoint: true,
      data: [],
    };
    const options = Object.assign({}, this.options);
    options.title.text = this.chartList[this.selectedTabIndex].title;
    options.subtitle = {
      text: unitStatusMka,
    };
    (item?.data || []).forEach((unitStatus: any) => {
      series.data.push({
        name: unitStatus.unitStatus,
        y: Number(unitStatus.count),
      });
    });
    options.series = [series];
    this.chart = new Chart(options);
    this.themeService.updateChartTheme(this.chart);
  }
}
