import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  DashboardComponent,
  AdminItemChartComponent,
  AdminColumnChartComponent,
  AdminPieChartComponent,
  AdminColumnGroupChartComponent
} from './index';
import { SharedModule } from 'src/app/shared';
import { ChartModule } from 'angular-highcharts';

@NgModule({
  declarations: [
    DashboardComponent,
    AdminItemChartComponent,
    AdminColumnChartComponent,
    AdminPieChartComponent,
    AdminColumnGroupChartComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChartModule
  ]
})
export class DashboardModule { }
