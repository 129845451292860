import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaterialThemeService, ThemeMode } from 'src/app/shared';
import { ApiAppResponse, ApiService } from 'src/app/_services';

import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import itemSeries from 'highcharts/modules/item-series';
import drilldown from 'highcharts/modules/drilldown';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
itemSeries(Highcharts);
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);

@Component({
  selector: 'app-admin-column-chart',
  templateUrl: './admin-column-chart.component.html',
  styleUrls: ['./admin-column-chart.component.scss'],
})
export class AdminColumnChartComponent implements OnInit, OnDestroy {
  public chartList: any[] = [
    {
      title: 'Zonal Wise Tickets Count',
      subtitle: 'Click the columns to view detailed view.',
      method: 'Zonal_Wise_Ticket',
      yAxis: {
        title: {
          text: 'No. of Tickets',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Tickets<br/>`,
      },
    },
    {
      title: "Zonal Wise Product's Units Count",
      subtitle: 'Click the columns to view detailed view.',
      method: 'Zonal_Wise_Product_Status',
      yAxis: {
        title: {
          text: 'No. of Units',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Units<br/>`,
      },
    },
    {
      title: "Zonal Wise Unit Status's Units Count",
      subtitle: 'Click the columns to view detailed view.',
      method: 'Zonal_Wise_Unit_Status',
      yAxis: {
        title: {
          text: 'No. of Units',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Units<br/>`,
      },
    },
  ];
  public selectedTabIndex: number = 0;
  public data: any[] = [];
  public chart = new Chart({});
  private options: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: {
        text: 'No. of Tickets',
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.name} ({point.y})',
        },
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> Tickets}<br/>',
    },

    series: [],
    drilldown: {
      series: [],
    },
  };
  private themeChangeSubscription!: Subscription;
  constructor(
    private apiService: ApiService,
    private themeService: MaterialThemeService
  ) {
    this.themeChangeSubscription = this.themeService
      .themeChangeObservable$()
      .subscribe((mode: ThemeMode) => {
        this.themeService.updateChartTheme(this.chart, mode);
      });
  }

  /**
   * On Init Method
   *
   * @memberof AdminColumnChartComponent
   */
  ngOnInit(): void {
    this.chart = new Chart(this.options);
    this.themeService.updateChartTheme(this.chart);
    this.onSelectedTabIndexChange(0);
  }

  /**
   * On Destroy Method
   *
   * @memberof AdminColumnChartComponent
   */
  ngOnDestroy() {
    if (this.themeChangeSubscription) {
      this.themeChangeSubscription.unsubscribe();
    }
  }

  /**
   * On Selected Tab Index Change
   *
   * @param {number} index
   * @memberof AdminColumnChartComponent
   */
  onSelectedTabIndexChange(index: number) {
    this.selectedTabIndex = index;
    this.apiService
      .adminChart(this.chartList[index].method)
      .subscribe((res: any) => {
        if (res.appresponse === ApiAppResponse.SUCCESS) {
          this.data = res.result || [];
          if (this.data?.length) {
            const series: any = {
              name: 'Zonal',
              colorByPoint: true,
              data: [],
            };
            const seriesData: any[] = [];
            const drilldownSeries: any[] = [];
            if (this.data?.length) {
              const options = Object.assign({}, this.options);
              options.title.text = this.chartList[index].title;
              options.subtitle.text = this.chartList[index].subtitle;
              options.yAxis = this.chartList[index].yAxis;
              options.tooltip = this.chartList[index].tooltip;
              (this.data || []).forEach((zonal: any) => {
                seriesData.push({
                  name: zonal.zonal,
                  y: zonal.count,
                  drilldown: zonal?.data?.length ? zonal.zonal : null,
                });
                if (zonal?.data?.length) {
                  const drilldownSeriesData: any = {
                    name: zonal.zonal,
                    id: zonal.zonal,
                    data: [],
                  };
                  switch (index) {
                    case 0:
                      (zonal?.data || []).forEach((regional: any) => {
                        drilldownSeriesData.data.push({
                          name: regional.regional,
                          y: regional.count,
                        });
                      });
                      break;

                    case 1:
                      (zonal?.data || []).forEach((product: any) => {
                        drilldownSeriesData.data.push({
                          name: product.product,
                          y: product.count,
                        });
                      });
                      break;

                    case 2:
                      (zonal?.data || []).forEach((unitStatus: any) => {
                        drilldownSeriesData.data.push({
                          name: unitStatus.unitStatus,
                          y: unitStatus.count,
                        });
                      });
                      break;

                    default:
                      break;
                  }
                  drilldownSeries.push(drilldownSeriesData);
                }
              });
              series.data = seriesData;
              options.series = [series];
              options.drilldown.series = drilldownSeries;
              this.chart = new Chart(options);
              this.themeService.updateChartTheme(this.chart);
            }
          }
        }
      });
  }
}
