import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbModule } from 'angular-crumbs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  CarouselComponent,
  DashboardTitleCountCardComponent,
  GridRowStatusComponent,
  GridRowMoreMenuComponent,
  ControlErrorComponent,
  EditableGridRowActionComponent,
  TicketLogTimelineComponent,
  AlertDialogComponent,
  AlertToastComponent,
  ProgressComponent,
  StarRatingComponent,
  GridRowActionButtonComponent,
} from './components';
import { DisabledContainerDirective, FullScreenDirective } from './directives';

@NgModule({
  declarations: [
    CarouselComponent,
    DashboardTitleCountCardComponent,
    GridRowStatusComponent,
    GridRowMoreMenuComponent,
    ControlErrorComponent,
    EditableGridRowActionComponent,
    TicketLogTimelineComponent,
    AlertDialogComponent,
    AlertToastComponent,
    ProgressComponent,
    StarRatingComponent,
    GridRowActionButtonComponent,
    DisabledContainerDirective,
    FullScreenDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    BreadcrumbModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatCardModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatProgressBarModule,
    MatSlideToggleModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BreadcrumbModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatChipsModule,
    MatTableModule,
    MatPaginatorModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatCardModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    CarouselComponent,
    DashboardTitleCountCardComponent,
    GridRowStatusComponent,
    GridRowMoreMenuComponent,
    ControlErrorComponent,
    EditableGridRowActionComponent,
    TicketLogTimelineComponent,
    AlertDialogComponent,
    AlertToastComponent,
    ProgressComponent,
    StarRatingComponent,
    GridRowActionButtonComponent,
    DisabledContainerDirective,
    FullScreenDirective,
  ],
})
export class SharedModule {}
