<div class="cd-timeline-title" [ngClass.gt-sm]="'gt-sm'">
  <div>Ticket Log</div>
</div>
<div class="cd-timeline" [ngClass.gt-sm]="'gt-sm'">
  <div class="cd-timeline-block" *ngFor="let log of ticketLog; let i=index; let o=odd">
    <div class="cd-timeline-circle"></div>
    <div class="cd-timeline-content">
      <div class="line-item">
        <div class="status">{{log?.logTicketStatus}}</div>
      </div>
      <div class="line-item">
        <div class="comment" [title]="log?.logRemarks">{{log?.logRemarks || 'No Comment'}}</div>
      </div>
      <div class="line-item" fxLayout="row-reverse" fxLayoutAlign="space-between start" fxLayoutGap="5px">
        <div class="timestamp">{{log?.logTicketCreatedDate | date: 'dd/MM/yyyy HH:mm'}}</div>
        <div class="attachment" *ngIf="log?.ticImageUrl">
          <a [href]="log?.ticImageUrl" target="_blank" rel="noopener noreferrer">Download Attachment</a>
        </div>
      </div>
    </div>
  </div>
