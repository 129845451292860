import { Injectable } from '@angular/core';
// import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private requestsRunning = 0;

  constructor(
    // private spinner: NgxSpinnerService
  ) { }

  public increase(): void {
    this.requestsRunning++;
    if (this.requestsRunning === 1) {
      // this.spinner.show();
    }
  }

  public decrease(): void {
    setTimeout(() => {
      if (this.requestsRunning > 0) {
        this.requestsRunning--;
        if (this.requestsRunning === 0) {
          // this.spinner.hide();
        }
      }
    });
  }
}
