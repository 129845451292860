export enum RootPaths {
  AUTH = 'auth',
  MAIN = 'app',
  ADMIN = 'admin',
  FEEDBACK = 'feedback',
  DEFAULT = 'auth',
}

export enum AuthPaths {
  LOGIN = 'login',
  DEFAULT = 'login',
}

export enum MainPaths {
  HOME = 'home',
  SERV_ORDER_GRID = 'service-order',
  REGION_WISE_CHART = 'region-wise-chart',
  DEFAULT = 'home',
}

export enum AdminPaths {
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  ESCALATION_MATRIX = 'escalation-matrix',
  TICKETS = 'tickets',
  REPORTS = 'reports',
  DEFAULT = 'dashboard',
}

export enum SettingsPaths {
  DASHBOARD = 'dashboard',
  DEFAULT = 'dashboard',
  ORGANIZATION_PROFILE = 'organization-profile',
  BRANCH = 'branch',
  MAIN_KEY_ACCOUNT = 'main-key-account',
  ZONAL = 'zonal',
  REGIONAL = 'regional',
  ZONAL_MAPPING = 'zonal-mapping',
  REGIONAL_MAPPING = 'regional-mapping',
  MODEL = 'model',
  CAPACITY = 'capacity',
  PRODUCT_MAPPING = 'product-mapping',
  SERVICE_ENGINEER_MASTER = 'service-engineer-master',
  I_BASE = 'i-base',
  ESCALATION_MATRIX = 'escalation-matrix',
  EXECUTIVE = 'executive',
  CALL_CATEGORY = 'call-category',
}

export enum OrganizationProfileSettingsPaths {
  PREVIEW = '',
  UPDATE = 'update',
}

export enum BranchSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
  IMPORT_EXCEL = 'import-excel',
}

export enum MainKeyAccountSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
}

export enum ZonalSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
  IMPORT_EXCEL = 'import-excel',
}

export enum RegionalSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
  IMPORT_EXCEL = 'import-excel',
}

export enum ZonalMappingSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
}

export enum RegionalMappingSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
}

export enum ModelSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
  IMPORT_EXCEL = 'import-excel',
}

export enum CapacitySettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
  IMPORT_EXCEL = 'import-excel',
}

export enum ProductMappingSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
  IMPORT_EXCEL = 'import-excel',
}

export enum ServiceEngineerMasterSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
  IMPORT_EXCEL = 'import-excel',
}

export enum IBaseSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
  IMPORT_EXCEL = 'import-excel',
}

export enum EscalationMatrixSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
}

export enum CallCategorySettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
}

export enum ExecutiveSettingsPaths {
  TABLE = '',
  CREATE = 'create',
  UPDATE = 'update',
  PREVIEW = 'preview',
}

export enum TicketsPaths {
  DASHBOARD = 'dashboard',
  CREATE = 'create-ticket',
  OPEN = 'open',
  WORK_IN_PROGRESS = 'work-in-progress',
  REQUEST_FOR_APPROVAL = 'request-for-approval',
  REQUEST_APPROVED = 'request-approved',
  HOLD = 'hold',
  RESOLVED = 'resolved',
  REOPEN = 'reopen',
  CLOSED = 'closed',
  DEFAULT = 'dashboard',
}

export enum ReportPaths {
  DASHBOARD = 'dashboard',
  DETAIL_REPORT = 'detail-report',
  TICKETS_STATUS = 'tickets-status',
  FEEDBACK_PERFORMANCE = 'feedback-performance',
  DATA_ANALYSIS = 'data-analysis',
  DEFAULT = 'dashboard',
}
