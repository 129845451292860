import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services';
import { RootPaths } from '../paths';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (next.data.auth && this.authService.isLoggedIn) {
      const redirectUrl = next.queryParams.redirectUrl;
      this.router.navigateByUrl(
        this.router.createUrlTree(
          [redirectUrl ? redirectUrl : RootPaths.ADMIN], {}
        )
      );

      return false;
    }

    if (!next.data.auth && !this.authService.isLoggedIn) {
      const redirectUrl = location.pathname;
      this.router.navigateByUrl(
        this.router.createUrlTree(
          [RootPaths.AUTH], {
          queryParams: {
            redirectUrl
          }
        }));

      return false;
    }

    return true;
  }

}
