import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Roles } from '../_enums';

export enum ApiAppResponse {
  SUCCESS = 'success',
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  /**
   * Verify User Login
   * @param data - post data
   */
  verifyUserLogin(data: any): Observable<any> {
    const url = environment.apiUrl + 'auth_api.php?method=verifyUserLogin';
    return this.http.post(url, data);
  }

  /**
   * Update Organization
   * @param data - post data
   */
  updateOrganization(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'organization_api.php?method=updateIdByOrganization';
    return this.http.post(url, data);
  }

  /**
   * Get Organization
   * @param data - post data
   */
  getOrganization(data: any): Observable<any> {
    const url =
      environment.apiUrl +
      'organization_api.php?method=getIdByOrganizationDetails';
    return this.http.post(url, data);
  }

  /**
   * Create / Update Branch
   * @param data - post data
   */
  createUpdateBranch(data: any): Observable<any> {
    const method = data.branchId ? 'updateIdByBranch' : 'addBranch';
    const url = environment.apiUrl + 'branch_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Branch By ID
   * @param data - post data
   */
  getBranchById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'branch_api.php?method=getIdByBranchDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Branch For Grid
   */
  getBranchForGrid(): Observable<any> {
    const url = environment.apiUrl + 'branch_api.php?method=getBranchList';
    return this.http.get(url);
  }

  /**
   * Get Branch DD
   */
  getBranchDD(): Observable<any> {
    const url = environment.apiUrl + 'branch_api.php?method=getBranchListDD';
    return this.http.get(url);
  }

  /**
   * Import Branch Preview
   * @param {File} file
   * @return {*}  {Observable<any>}
   */
  importBranchPreview(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('method', 'preview');
    const url = environment.apiUrl + 'branch_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Branch Validate
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importBranchValidate(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'validate');
    const url = environment.apiUrl + 'branch_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Branch Store
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importBranchStore(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'store');
    const url = environment.apiUrl + 'branch_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Create / Update Main Key Account
   */
  createUpdateMainKeyAccount(data: any): Observable<any> {
    const method = data.mkaId ? 'updateIdByMainKeyAcc' : 'addMainKeyAcc';
    const url = environment.apiUrl + 'mainkeyacc_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Main Key Account By ID
   */
  getMainKeyAccountById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'mainkeyacc_api.php?method=getIdByMainKeyAccDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Main Key Account For Grid
   */
  getMainKeyAccountForGrid(): Observable<any> {
    const url =
      environment.apiUrl + 'mainkeyacc_api.php?method=getMainKeyAccList';
    return this.http.get(url);
  }

  /**
   * Get Main Key Account For DD
   */
  getMainKeyAccountForDD(): Observable<any> {
    const url =
      environment.apiUrl + 'mainkeyacc_api.php?method=mainKeyAccountListDD';
    return this.http.get(url);
  }

  /**
   * Create / Update Zonal
   */
  createUpdateZonal(data: any): Observable<any> {
    const method = data.zonalId ? 'updateIdByZonal' : 'addZonal';
    const url = environment.apiUrl + 'zonal_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Zonal By ID
   */
  getZonalById(data: any): Observable<any> {
    const url = environment.apiUrl + 'zonal_api.php?method=getIdByZonalDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Zonal For Grid
   */
  getZonalForGrid(): Observable<any> {
    const url = environment.apiUrl + 'zonal_api.php?method=getZonalList';
    return this.http.get(url);
  }

  /**
   * Get Zonal For DD
   */
  getZonalForDD(): Observable<any> {
    const url = environment.apiUrl + 'zonal_api.php?method=getZoneListDD';
    return this.http.get(url);
  }

  /**
   * Import Zonal Preview
   * @param {File} file
   * @return {*}  {Observable<any>}
   */
  importZonalPreview(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('method', 'preview');
    const url = environment.apiUrl + 'zonal_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Zonal Validate
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importZonalValidate(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'validate');
    const url = environment.apiUrl + 'zonal_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Zonal Store
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importZonalStore(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'store');
    const url = environment.apiUrl + 'zonal_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Create / Update Regional
   */
  createUpdateRegional(data: any): Observable<any> {
    const method = data.regionalId ? 'updateIdByRegional' : 'addRegional';
    const url = environment.apiUrl + 'regional_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Regional By ID
   */
  getRegionalById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'regional_api.php?method=getIdByRegionalDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Regional For Grid
   */
  getRegionalForGrid(): Observable<any> {
    const url = environment.apiUrl + 'regional_api.php?method=getRegionalList';
    return this.http.get(url);
  }

  /**
   * Get Regional For DD
   */
  getRegionalForDD(data: any): Observable<any> {
    const url = environment.apiUrl + 'regional_api.php?method=getRegionListDD';
    return this.http.post(url, data);
  }

  /**
   * Import Regional Preview
   * @param {File} file
   * @return {*}  {Observable<any>}
   */
  importRegionalPreview(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('method', 'preview');
    const url = environment.apiUrl + 'regional_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Regional Validate
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importRegionalValidate(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'validate');
    const url = environment.apiUrl + 'regional_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Regional Store
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importRegionalStore(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'store');
    const url = environment.apiUrl + 'regional_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Create / Update ZonalMap
   */
  createUpdateZonalMap(data: any): Observable<any> {
    const method = data.zmapId ? 'updateIdByZonalMap' : 'addZonalMap';
    const url = environment.apiUrl + 'zonalmap_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get ZonalMap By ID
   */
  getZonalMapById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'zonalmap_api.php?method=getIdByZonalMapDetails';
    return this.http.post(url, data);
  }

  /**
   * Get ZonalMap For Grid
   */
  getZonalMapForGrid(): Observable<any> {
    const url = environment.apiUrl + 'zonalmap_api.php?method=getZonalMapList';
    return this.http.get(url);
  }

  /**
   * Get ZonalMap For DD
   */
  getZonalMapForDD(): Observable<any> {
    const url =
      environment.apiUrl + 'zonalmap_api.php?method=getZonalMapListDD';
    return this.http.get(url);
  }

  /**
   * Create / Update RegionalMap
   */
  createUpdateRegionalMap(data: any): Observable<any> {
    const method = data.rmapId ? 'updateIdByRegionalMap' : 'addRegionalMap';
    const url = environment.apiUrl + 'regionalmap_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get RegionalMap By ID
   */
  getRegionalMapById(data: any): Observable<any> {
    const url =
      environment.apiUrl +
      'regionalmap_api.php?method=getIdByRegionalMapDetails';
    return this.http.post(url, data);
  }

  /**
   * Get RegionalMap For Grid
   */
  getRegionalMapForGrid(): Observable<any> {
    const url =
      environment.apiUrl + 'regionalmap_api.php?method=getRegionalMapList';
    return this.http.get(url);
  }

  /**
   * Get RegionalMap For DD
   */
  getRegionalMapForDD(): Observable<any> {
    const url =
      environment.apiUrl + 'regionalmap_api.php?method=getRegionalMapListDD';
    return this.http.get(url);
  }

  /**
   * Create / Update Service Engineer
   */
  createUpdateEngineer(data: any): Observable<any> {
    const method = data.engId ? 'updateIdByServEng' : 'addServEng';
    const url = environment.apiUrl + 'serveng_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Service Engineer By ID
   */
  getEngineerById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'serveng_api.php?method=getIdByServEngDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Service Engineer For Grid
   */
  getEngineerForGrid(): Observable<any> {
    const url = environment.apiUrl + 'serveng_api.php?method=getServEngList';
    return this.http.get(url);
  }

  // /**
  //  * Get Service Engineer List DD
  //  */
  // getEngineerListDD(): Observable<any> {
  //   const url = environment.apiUrl + 'serveng_api.php?method=getServEngListDD';
  //   return this.http.get(url);
  // }

  /**
   * Get Service Engineer List DD By Branch ID
   */
  getEngineerListDDByBranchId(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'serveng_api.php?method=getServEngListDDByBranchId';
    return this.http.post(url, data);
  }

  /**
   * Import Engineer Preview
   * @param {File} file
   * @return {*}  {Observable<any>}
   */
  importEngineerPreview(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('method', 'preview');
    const url = environment.apiUrl + 'service_engineer_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Engineer Validate
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importEngineerValidate(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'validate');
    const url = environment.apiUrl + 'service_engineer_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Engineer Store
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importEngineerStore(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'store');
    const url = environment.apiUrl + 'service_engineer_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Create / Update Model
   */
  createUpdateModel(data: any): Observable<any> {
    const method = data.modelId ? 'updateIdByModel' : 'addModel';
    const url = environment.apiUrl + 'model_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Model By ID
   */
  getModelById(data: any): Observable<any> {
    const url = environment.apiUrl + 'model_api.php?method=getIdByModelDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Model For Grid
   */
  getModelForGrid(): Observable<any> {
    const url = environment.apiUrl + 'model_api.php?method=getModelList';
    return this.http.get(url);
  }

  /**
   * Get Model For DD
   */
  getModelForDD(): Observable<any> {
    const url = environment.apiUrl + 'model_api.php?method=getModelListDD';
    return this.http.get(url);
  }

  /**
   * Import Model Preview
   * @param {File} file
   * @return {*}  {Observable<any>}
   */
  importModelPreview(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('method', 'preview');
    const url = environment.apiUrl + 'model_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Model Validate
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importModelValidate(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'validate');
    const url = environment.apiUrl + 'model_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Model Store
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importModelStore(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'store');
    const url = environment.apiUrl + 'model_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Create / Update Capacity
   */
  createUpdateCapacity(data: any): Observable<any> {
    const method = data.capacityId ? 'updateIdByCapacity' : 'addCapacity';
    const url = environment.apiUrl + 'capacity_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Capacity By ID
   */
  getCapacityById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'capacity_api.php?method=getIdByCapacityDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Capacity For Grid
   */
  getCapacityForGrid(): Observable<any> {
    const url = environment.apiUrl + 'capacity_api.php?method=getCapacityList';
    return this.http.get(url);
  }

  /**
   * Get Capacity For DD
   */
  getCapacityForDD(): Observable<any> {
    const url =
      environment.apiUrl + 'capacity_api.php?method=getCapacityListDD';
    return this.http.get(url);
  }

  /**
   * Import Capacity Preview
   * @param {File} file
   * @return {*}  {Observable<any>}
   */
  importCapacityPreview(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('method', 'preview');
    const url = environment.apiUrl + 'capacity_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Capacity Validate
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importCapacityValidate(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'validate');
    const url = environment.apiUrl + 'capacity_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import Capacity Store
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importCapacityStore(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'store');
    const url = environment.apiUrl + 'capacity_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Create / Update Product Map
   */
  createUpdateProductMap(data: any): Observable<any> {
    const method = data.promapId ? 'updateIdByProductMap' : 'addProductMap';
    const url = environment.apiUrl + 'productmap_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Product Map By ID
   */
  getProductMapById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'productmap_api.php?method=getIdByProductMapDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Product Map For Grid
   */
  getProductMapForGrid(): Observable<any> {
    const url =
      environment.apiUrl + 'productmap_api.php?method=getProductMapList';
    return this.http.get(url);
  }

  /**
   * Get Product Map For DD
   */
  getProductMapForDD(): Observable<any> {
    const url =
      environment.apiUrl + 'productmap_api.php?method=getProductMapListDD';
    return this.http.get(url);
  }

  /**
   * Import ProductMapping Preview
   * @param {File} file
   * @return {*}  {Observable<any>}
   */
  importProductMappingPreview(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('method', 'preview');
    const url = environment.apiUrl + 'productmap_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import ProductMapping Validate
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importProductMappingValidate(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'validate');
    const url = environment.apiUrl + 'productmap_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import ProductMapping Store
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importProductMappingStore(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'store');
    const url = environment.apiUrl + 'productmap_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Create / Update Escalation MAtrix
   */
  createUpdateEscalationMatrix(data: any): Observable<any> {
    const method = data.escId ? 'updateIdByEscalation' : 'addEscalation';
    const url = environment.apiUrl + 'escalation_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Escalation Matrix By ID
   */
  getEscalationMatrixById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'escalation_api.php?method=getIdByEscalationDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Escalation Matrix For Grid
   */
  getEscalationMatrixForGrid(): Observable<any> {
    const url =
      environment.apiUrl + 'escalation_api.php?method=getEscalationList';
    return this.http.get(url);
  }

  // /**
  //  * Get Escalation Matrix For DD
  //  */
  // getEscalationMatrixForDD(): Observable<any> {
  //   const url = environment.apiUrl + 'escalation_api.php?method=getEscalationListDD';
  //   return this.http.get(url);
  // }

  /**
   * Create / Update Escalation MAtrix Level
   */
  createUpdateEscalationMatrixLevel(data: any): Observable<any> {
    const method = data.escId ? 'updateIdByLevel' : 'addLevel';
    const url = environment.apiUrl + 'escmatrixlevel_api.php?method=' + method;
    return this.http.post(url, data);
  }

  // /**
  //  * Get Escalation Matrix Level By ID
  //  */
  // getEscalationMatrixLevelById(data: any): Observable<any> {
  //   const url = environment.apiUrl + 'escmatrixlevel_api.php?method=getIdByLevelDetails';
  //   return this.http.post(url, data);
  // }

  // /**
  //  * Get Escalation Matrix Level For Grid
  //  */
  // getEscalationMatrixLevelForGrid(): Observable<any> {
  //   const url = environment.apiUrl + 'escmatrixlevel_api.php?method=getLevelList';
  //   return this.http.get(url);
  // }

  /**
   * Get Escalation Matrix Level For DD
   */
  getEscalationMatrixLevelForDD(): Observable<any> {
    const url =
      environment.apiUrl + 'escmatrixlevel_api.php?method=getLevelListDD';
    return this.http.get(url);
  }

  /**
   * Create / Update Executive
   */
  createUpdateExecutive(data: any): Observable<any> {
    const method = data.execId ? 'updateIdByExecutive' : 'addExecutive';
    const url = environment.apiUrl + 'executive_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get Executive By ID
   */
  getExecutiveById(data: any): Observable<any> {
    const url =
      environment.apiUrl +
      'executive_api.php?method=getIdBygetExecutiveDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Executive For Grid
   */
  getExecutiveForGrid(): Observable<any> {
    const url =
      environment.apiUrl + 'executive_api.php?method=getExecutiveList';
    return this.http.get(url);
  }

  // /**
  //  * Get Executive For DD
  //  */
  // getExecutiveForDD(): Observable<any> {
  //   const url = environment.apiUrl + 'executive_api.php?method=getExecutiveListDD';
  //   return this.http.get(url);
  // }

  /**
   * Create / Update Call Category
   * @param {*} data
   * @return {*}  {Observable<any>}
   */
  createUpdateCallCategory(data: any): Observable<any> {
    const method = data.callCategoryId
      ? 'updateIdByCallCategory'
      : 'addCallCategory';
    const url = environment.apiUrl + 'call_category_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get CallCategory By ID
   */
  getCallCategoryById(data: any): Observable<any> {
    const url =
      environment.apiUrl +
      'call_category_api.php?method=getIdByCallCategoryDetails';
    return this.http.post(url, data);
  }

  /**
   * Get CallCategory For Grid
   */
  getCallCategoryForGrid(): Observable<any> {
    const url =
      environment.apiUrl + 'call_category_api.php?method=getCallCategoryList';
    return this.http.get(url);
  }

  /**
   * Get CallCategory For DD
   */
  getCallCategoryForDD(): Observable<any> {
    const url =
      environment.apiUrl + 'call_category_api.php?method=getCallCategoryListDD';
    return this.http.get(url);
  }

  /**
   * Create / Update IBase Map
   */
  createUpdateIBase(data: any): Observable<any> {
    const method = data.ibaseId ? 'updateIdByIBase' : 'addIBase';
    const url = environment.apiUrl + 'ibase_api.php?method=' + method;
    return this.http.post(url, data);
  }

  /**
   * Get IBase By ID
   */
  getIBaseById(data: any): Observable<any> {
    const url = environment.apiUrl + 'ibase_api.php?method=getIdByIBaseDetails';
    return this.http.post(url, data);
  }

  /**
   * Get IBase For Grid
   */
  getIBaseForGrid(): Observable<any> {
    const url = environment.apiUrl + 'ibase_api.php?method=getIBaseList';
    return this.http.get(url);
  }

  /**
   * Change IBase Status
   * @param ibaseId - IBase ID
   * @param status IBase Status
   * @returns 
   */
  changeIBaseStatus(ibaseId: string, status: boolean): Observable<any> {
    const ibaseStatus = status ? 'active' : 'inactive';
    const url = environment.apiUrl + 'ibase_api.php?method=updateIdByIBaseStatus';
    return this.http.post(url, {ibaseId, ibaseStatus});
  }

  /**
   * Import IBase Preview
   * @param {File} file
   * @return {*}  {Observable<any>}
   */
  importIBasePreview(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('method', 'preview');
    const url = environment.apiUrl + 'ibase_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import IBase Validate
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importIBaseValidate(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'validate');
    const url = environment.apiUrl + 'ibase_import_api.php';
    return this.http.post(url, formData);
  }

  /**
   * Import IBase Store
   * @param {any[]} data
   * @return {*}  {Observable<any>}
   */
  importIBaseStore(data: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('method', 'store');
    const url = environment.apiUrl + 'ibase_import_api.php';
    return this.http.post(url, formData);
  }

  // /**
  //  * Upload IBase Excel
  //  */
  // uploadIBaseExcel(data: any): Observable<any> {
  //   const url = environment.apiUrl + 'ibase_api.php?method=uploadExcel';
  //   return this.http.post(url, data);
  // }

  /**
   * Get Site / ATM ID List Search
   * @param data 
   * @returns 
   */
  getSiteATMListSearch(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'ticket_api.php?method=getGroupBySiteAtmId&load=0';
    return this.http.post(url, data);
  }

  /**
   * Get Unit Serial List Search
   * @param data - post data
   */
  getUnitSerialListSearch(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'ticket_api.php?method=getGroupBySiteIdList&load=0';
    return this.http.post(url, data);
  }

  /**
   * Get Site / ATM details by ID
   * @param data - post data
   */
  getSiteATMDetailsById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'ticket_api.php?method=getIdBySiteIdDetails';
    return this.http.post(url, data);
  }

  /**
   * Create Ticket
   * @param data - post data
   */
  createTicket(data: any): Observable<any> {
    const url = environment.apiUrl + 'ticket_api.php?method=addTicket';
    return this.http.post(url, data);
  }

  /**
   * Get Tickets By Status For Grid
   * @param data - post data
   */
  getTicketsByStatusForGrid(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'ticket_api.php?method=getStatusByTicketDetails';
    return this.http.post(url, data);
  }

  /**
   * Get Ticket By Ticket ID
   * @param data - post data
   */
  getTicketById(data: any): Observable<any> {
    const url =
      environment.apiUrl + 'ticket_api.php?method=getIdByTicketDetails';
    return this.http.post(url, data);
  }

  // /**
  //  * Get Service Engineer List
  //  */
  // getServiceEngineerList(): Observable<any> {
  //   const url = environment.apiUrl + 'ticket_api.php?method=getIdByServiceEngList';
  //   return this.http.get(url);
  // }

  /**
   * Change Ticker Status
   * @param data - post data
   */
  changeTicketStatus(data: any): Observable<any> {
    const url = environment.apiUrl + 'ticket_api.php?method=changeTicketStatus';
    return this.http.post(url, data);
  }

  /**
   * Download Tickets
   * @param data - post data
   */
  downloadTickets(data: any): Observable<any> {
    const url = environment.apiUrl + 'ticket_api.php?method=ticketsdownload';
    return this.http.post(url, data);
  }

  // dashboardIBaseReport(roleId: string): Observable<any> {
  //   const method = 'proMapBasedIBaseCountsFor' + (roleId === Roles.Admin ? 'Admin' : roleId === Roles.KeyAccount ? 'MKA' : 'Zonal');
  //   const url = environment.apiUrl + 'report_api.php?method=' + method;
  //   return this.http.get(url);
  // }

  // dashboardUnitStatusReport(roleId: string): Observable<any> {
  //   const method = 'unitStatusBasedIBaseCountsFor' + (roleId === Roles.Admin ? 'Admin' : roleId === Roles.KeyAccount ? 'MKA' : 'Zonal');
  //   const url = environment.apiUrl + 'report_api.php?method=' + method;
  //   return this.http.get(url);
  // }

  // dashboardTicketCountsReport(data: any): Observable<any> {
  //   const url = environment.apiUrl + 'report_api.php?method=ticketCountsByTicketStatus';
  //   return this.http.post(url, data);
  // }

  // getTicketCountsReportByMka(data: any): Observable<any> {
  //   const url = environment.apiUrl + 'report_api.php?method=allTicketStatusCounts';
  //   return this.http.post(url, data);
  // }

  // /**
  //  * Submit Feedback
  //  * @param data - data
  //  */
  // submitFeedback(data: any): Observable<any> {
  //   const url = environment.apiUrl + 'customerfeedback_api.php?method=submitFeedback';
  //   return this.http.post(url, data);
  // }

  // /**
  //  * Feedback Ticket List
  //  */
  // feedbackTicketList(): Observable<any> {
  //   const url = environment.apiUrl + 'customerfeedback_api.php?method=feedbackGrid';
  //   return this.http.get(url);
  // }

  // /**
  //  * Get Feedback By Ticket ID
  //  * @param ticketId - ticketId
  //  */
  // getFeedbackByTicketId(ticketId: string): Observable<any> {
  //   const url = environment.apiUrl + 'customerfeedback_api.php?method=getFeedbackByTicketId';
  //   return this.http.post(url, { ticketId });
  // }

  /**
   * Ticket Unit Serial No Validation
   * @param unitSerialNoRefId - Unit Serial No
   */
  ticketUnitSerialNoValidation(unitSerialNoRefId: string): Observable<any> {
    const url = environment.apiUrl + 'ticket_api.php?method=ticketValidation';
    return this.http.post(url, { unitSerialNoRefId });
  }

  /**
   * Ticket Unit Serial No Status Validation
   * @param unitSerialNoRefId - Unit Serial No
   */
  ticketUnitSerialNoStatusValidation(
    unitSerialNoRefId: string
  ): Observable<any> {
    const url =
      environment.apiUrl + 'ticket_api.php?method=ticketStatusValidation';
    return this.http.post(url, { unitSerialNoRefId });
  }

  // ticketStatusReport(data: any): Observable<any> {
  //   const url = environment.apiUrl + 'report_api.php?method=ticketStatusReport';
  //   return this.http.post(url, data);
  // }

  settingsRecordsCount(): Observable<any> {
    const url =
      environment.apiUrl + 'chart_api.php?method=Dashboard_Widget_Details';
    return this.http.get(url);
  }

  // Dashboard Chart APIs starts here

  /**
   * Admin Chart
   *
   * @return {*}
   * @memberof ApiService
   */
  adminChart(method: string): Observable<any> {
    const url = environment.apiUrl + 'chart_api.php?method=' + method;
    return this.http.get(url);
  }

  /**
   * Main Key Account Wise Ticket Status Counts
   *
   * @return {*}
   * @memberof ApiService
   */
  mainKeyAccountWiseTicketStatus(): Observable<any> {
    const url =
      environment.apiUrl +
      'chart_api.php?method=Main_Key_Account_Wise_Ticket_Status';
    return this.http.get(url);
  }

  /**
   * Zonal Wise Ticket Count
   *
   * @return {*}  {Observable<any>}
   * @memberof ApiService
   */
  zonalWiseTicketCount(): Observable<any> {
    const url = environment.apiUrl + 'chart_api.php?method=Zonal_Wise_Ticket';
    return this.http.get(url);
  }

  // Dhashboard Chart APIs ends here

  /**
   * Send Feedback
   *
   * @param {*} [data]
   * @return {*}  {Observable<any>}
   * @memberof ApiService
   */
  sendFeedback(data?: any): Observable<any> {
    const url = environment.apiUrl + 'feedback_api.php?method=addFeedback';
    return this.http.post(url, data);
  }
}
