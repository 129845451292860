import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaterialThemeService, ThemeMode } from 'src/app/shared';
import { ApiAppResponse, ApiService } from 'src/app/_services';

import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
exporting(Highcharts);
exportData(Highcharts);

@Component({
  selector: 'app-admin-column-group-chart',
  templateUrl: './admin-column-group-chart.component.html',
  styleUrls: ['./admin-column-group-chart.component.scss'],
})
export class AdminColumnGroupChartComponent implements OnInit {
  public chartList: any[] = [
    {
      title: 'Branch Wise Tickets Count',
      subtitle: 'Click the columns to view detailed view.',
      method: 'Branch_Status_Wise_Ticket_Status',
    },
    {
      title: "Branch Wise Product's Tickets Count",
      subtitle: 'Click the columns to view detailed view.',
      method: 'Branch_Product_Wise_Ticket_Status',
    },
    {
      title: "Branch Wise Unit Status's Tickets Count",
      subtitle: 'Click the columns to view detailed view.',
      method: 'Branch_Unit_Wise_Ticket_Status',
    },
  ];
  public selectedTabIndex: number = 0;
  public data: any[] = [];
  public chart = new Chart({});
  private options: any = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Monthly Average Rainfall',
    },
    subtitle: {
      text: 'Source: WorldClimate.com',
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      min: 0,
      title: {
        text: 'No. of Tickets',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y} Tickets</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      // {
      //   name: 'Tokyo',
      //   data: [{ name: 'Jan', y: 49.9 }, { name: 'feb', y: 71.5 }, { name: 'Mar', y: 106.4 }, { name: 'Apr', y: 129.2 }, { name: 'May', y: 144.0 }, { name: 'June', y: 176.0 }, { name: 'July', y: 135.6 }, { name: 'Aug', y: 148.5 }, { name: 'Sep', y: 216.4 }, { name: 'Oct', y: 194.1 }, { name: 'Nov', y: 95.6 }, { name: 'Dec', y: 54.4 }]
      // }, {
      //   name: 'New York',
      //   data: [{ name: 'feb', y: 78.8 }, { name: 'Mar', y: 98.5 }, { name: 'Apr', y: 93.4 }, { name: 'May', y: 106.0 }, { name: 'June', y: 84.5 }, { name: 'July', y: 105.0 }, { name: 'Aug', y: 104.3 }, { name: 'Sep', y: 91.2 }, { name: 'Oct', y: 83.5 }, { name: 'Nov', y: 106.6 }, { name: 'Dec', y: 92.3 }]
      // }, {
      //   name: 'London',
      //   data: [{ name: 'Jan', y: 48.9 }, { name: 'feb', y: 38.8 }, { name: 'Mar', y: 39.3 }, { name: 'Apr', y: 41.4 }, { name: 'May', y: 47.0 }, { name: 'June', y: 48.3 }, { name: 'July', y: 59.0 }, { name: 'Aug', y: 59.6 }, { name: 'Sep', y: 52.4 }, { name: 'Oct', y: 65.2 }, { name: 'Nov', y: 59.3 }, { name: 'Dec', y: 51.2 }]
      // }, {
      //   name: 'Berlin',
      //   data: [{ name: 'Jan', y: 42.4 }, { name: 'feb', y: 33.2 }, { name: 'Mar', y: 34.5 }, { name: 'Apr', y: 39.7 }, { name: 'May', y: 52.6 }, { name: 'June', y: 75.5 }, { name: 'July', y: 57.4 }, { name: 'Aug', y: 60.4 }, { name: 'Sep', y: 47.6 }, { name: 'Oct', y: 39.1 }, { name: 'Nov', y: 46.8 }, { name: 'Dec', y: 51.1 }]
      // }
    ],
  };
  private themeChangeSubscription!: Subscription;
  constructor(
    private apiService: ApiService,
    private themeService: MaterialThemeService
  ) {
    this.themeChangeSubscription = this.themeService
      .themeChangeObservable$()
      .subscribe((mode: ThemeMode) => {
        this.themeService.updateChartTheme(this.chart, mode);
      });
  }

  /**
   * On Init Method
   *
   * @memberof AdminColumnGroupChartComponent
   */
  ngOnInit(): void {
    this.chart = new Chart(this.options);
    this.themeService.updateChartTheme(this.chart);
    this.onSelectedTabIndexChange(0);
  }

  /**
   * On Destroy Method
   *
   * @memberof AdminColumnGroupChartComponent
   */
  ngOnDestroy() {
    if (this.themeChangeSubscription) {
      this.themeChangeSubscription.unsubscribe();
    }
  }

  /**
   * On Select Tab Index Change
   *
   * @param {number} index
   * @memberof AdminColumnGroupChartComponent
   */
  onSelectedTabIndexChange(index: number) {
    this.selectedTabIndex = index;
    this.apiService
      .adminChart(this.chartList[index].method)
      .subscribe((res: any) => {
        if (res.appresponse === ApiAppResponse.SUCCESS) {
          this.data = res.result || [];

          if (this.data?.length) {
            const options = Object.assign({}, this.options);
            options.series = [];
            options.title.text = this.chartList[index].title;
            options.subtitle.text = this.chartList[index].subtitle;
            (this.data || []).forEach((data: any) => {
              switch (index) {
                case 0:
                  const statusSeries: any = {
                    name: data.status,
                    data: [],
                  };
                  const statusSeriesData: any[] = [];
                  if (data?.data?.length) {
                    (data?.data || []).forEach((branch: any) => {
                      statusSeriesData.push({
                        name: branch.branch,
                        y: branch.count,
                      });
                    });
                  }
                  statusSeries.data = statusSeriesData;
                  options.series.push(statusSeries);
                  break;

                case 1:
                  const productSeries: any = {
                    name: data.product,
                    data: [],
                  };
                  const productSeriesData: any[] = [];
                  if (data?.data?.length) {
                    (data?.data || []).forEach((branch: any) => {
                      productSeriesData.push({
                        name: branch.branch,
                        y: branch.count,
                      });
                    });
                  }
                  productSeries.data = productSeriesData;
                  options.series.push(productSeries);
                  break;

                case 2:
                  const unitStatusSeries: any = {
                    name: data.unitStatus,
                    data: [],
                  };
                  const unitStatusSeriesData: any[] = [];
                  if (data?.data?.length) {
                    (data?.data || []).forEach((branch: any) => {
                      unitStatusSeriesData.push({
                        name: branch.branch,
                        y: branch.count,
                      });
                    });
                  }
                  unitStatusSeries.data = unitStatusSeriesData;
                  options.series.push(unitStatusSeries);
                  break;

                default:
                  break;
              }
            });
            console.log(options.series);
            this.chart = new Chart(options);
            this.themeService.updateChartTheme(this.chart);
          }
        }
      });
  }
}
