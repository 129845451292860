import { Component, Input, OnInit } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {
  @Input() message: string = '';
  @Input() mode: ProgressBarMode = 'indeterminate';

  constructor() {}

  ngOnInit(): void {}
}
