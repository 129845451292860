<div class="editable-grid-row-action">
  <button mat-icon-button color="primary" [matTooltip]="editedRowIndex === undefined ? 'Edit' : 'Save'"
    [disabled]="!(editedRowIndex === undefined || editedRowIndex === rowIndex)"
    (click)="editedRowIndex === undefined ? onEdit.emit(rowIndex) : onSave.emit(rowIndex)">
    <mat-icon>{{editedRowIndex === rowIndex ? 'check_circle' : 'edit'}}</mat-icon>
  </button>
  <button mat-icon-button color="primary" [matTooltip]="editedRowIndex === undefined ? 'Delete' : 'Cancel'"
    [disabled]="!(editedRowIndex === undefined || editedRowIndex === rowIndex)"
    (click)="editedRowIndex === undefined ? onRemove.emit(rowIndex) : onCancel.emit(rowIndex)">
    <mat-icon>{{editedRowIndex === rowIndex ? 'cancel' : 'delete'}}</mat-icon>
  </button>
</div>
