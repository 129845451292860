<div>
  <div class="dashboard-count-title-card" [routerLink]="'../' + data?.link">
    <div class="icon" fxLayoutAlign="center center">
      <mat-icon>{{data?.icon}}</mat-icon>
    </div>
    <div class="title">{{data?.title}}</div>
    <div class="count">{{count || 0}}</div>
    <div class="message">Get more space</div>
    <div class="ripple" matRipple></div>
  </div>
</div>
