import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services';
import { Roles } from '../_enums';
import { RootPaths } from '../paths';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const restrictedRoles = next.data.restrictedRoles;
    const user = this.authService.getAuthUser();
    let role: any;
    if (restrictedRoles && restrictedRoles instanceof Array) {
      role = restrictedRoles.find((restrictedRole: Roles) => {
        return restrictedRole === user.role;
      });
    }
    if (role !== undefined) {

      this.router.navigateByUrl(
        this.router.createUrlTree([RootPaths.ADMIN])
      );

      return false;
    }
    return true;
  }
}
