import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AlertTypes, AlertIcons } from './../../enums';

@Component({
  selector: 'tintpro-alert-toast',
  templateUrl: './alert-toast.component.html',
  styleUrls: ['./alert-toast.component.scss']
})
export class AlertToastComponent {
  public icon: AlertIcons = AlertIcons.INFO;
  public header = 'Information!';
  public color = '#007BC2';

  constructor(
    public snackbarRef: MatSnackBarRef<AlertToastComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    switch (data?.type) {
      case AlertTypes.SUCCESS:
        this.header = data?.header || 'Success!';
        this.icon = AlertIcons.SUCCESS;
        this.color = '#21A67A';
        break;

      case AlertTypes.ALERT:
        this.header = data?.header || 'Alert!';
        this.icon = AlertIcons.ALERT;
        this.color = '#F14D48';
        break;

      case AlertTypes.WARN:
        this.header = data?.header || 'Warning!';
        this.icon = AlertIcons.WARN;
        this.color = '#FF9D0A';
        break;

      default:
        this.header = data?.header || 'Information!';
        this.icon = AlertIcons.INFO;
        this.color = '#007BC2';
        break;
    }
  }

  // On Dismiss
  onDismiss() {
    this.snackbarRef.dismissWithAction();
  }

}
