import { Component, Input, Optional } from '@angular/core';
import { FormControl, AbstractControl, FormGroup } from '@angular/forms';

type D = any;

@Component({
  selector: 'mat-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent {

  @Input() control!: AbstractControl;

  @Input() path!: string;

  @Input() message: D = {};

  private get _control(): AbstractControl | null {
    return this.control instanceof FormControl ? this.control : this.control.get((this.path || '').toString());
  }

  public get isError(): boolean | null {
    return this._control && (this._control.invalid && this._control.touched);
  }

  private get errors(): D {
    return this._control ? this._control.errors : null;
  }

  public get displayMessage() {
    let message = '';
    Object.keys(this.errors).forEach(property => {
      if (this.path) {
        message = this.message[this.path][property] || '';
      } else {
        message = this.message[property] || '';
      }
    });
    return message;
  }

}
