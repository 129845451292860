import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertTypes, AlertIcons } from './../../enums';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

  public icon: AlertIcons = AlertIcons.INFO;
  public header = 'Information!';
  public showOkay = false;
  public showCancel = false;
  public okayText = 'Okay';
  public cancelText = 'Cancel';

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    switch (data?.type) {
      case AlertTypes.SUCCESS:
        this.header = data?.header || 'Success!';
        this.icon = data?.icon || AlertIcons.SUCCESS;
        this.showOkay = true;
        this.okayText = data?.okayText || 'Okay';
        break;

      case AlertTypes.ALERT:
        this.header = data?.header || 'Alert!';
        this.icon = data?.icon || AlertIcons.ALERT;
        this.showCancel = true;
        this.cancelText = data?.cancelText || 'Close';
        break;

      case AlertTypes.WARN:
        this.header = data?.header || 'Warning!';
        this.icon = data?.icon || AlertIcons.WARN;
        this.showOkay = true;
        this.okayText = data?.okayText || 'Okay';
        break;

      case AlertTypes.CONFIRM:
        this.header = data?.header || 'Confirm!';
        this.icon = data?.icon || AlertIcons.WARN;
        this.showOkay = true;
        this.okayText = data?.okayText || 'Okay';
        this.showCancel = true;
        this.cancelText = data?.cancelText || 'Cancel';
        break;

      default:
        this.header = data?.header || 'Information!';
        this.icon = data?.icon || AlertIcons.INFO;
        this.showOkay = true;
        this.okayText = data?.okayText || 'Okay';
        break;
    }
  }

  // On Click Close
  onClickClose() {
    this.dialogRef.close();
  }

  // On Click Okay
  onClickOk() {
    this.dialogRef.close(true);
  }

}
