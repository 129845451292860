import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RootPaths } from './paths';
import { AuthGuard } from './_guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: RootPaths.DEFAULT,
    pathMatch: 'full',
  },
  {
    path: RootPaths.AUTH,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard],
    data: {
      auth: true,
      breadcrumb: 'Authentication',
    },
  },
  {
    path: RootPaths.ADMIN,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard],
    data: {
      auth: false,
      breadcrumb: 'Admin',
    },
  },
  {
    path: RootPaths.FEEDBACK,
    loadChildren: () =>
      import('./feedback/feedback.module').then((m) => m.FeedbackModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
