<div class="dashboard-chart-container">
  <div [chart]="chart"></div>
  <mat-button-toggle-group *ngIf="data?.length && selectedTabIndex === 0" [(ngModel)]="ticketStatus"
    (change)="onSelectStatus($event.value)">
    <mat-button-toggle *ngFor="let item of data" [value]="item?.status">{{item?.status}}</mat-button-toggle>
  </mat-button-toggle-group>
  <mat-button-toggle-group *ngIf="data?.length && selectedTabIndex === 1" [(ngModel)]="unitStatus"
    (change)="onSelectUnitStatus($event.value)">
    <mat-button-toggle *ngFor="let item of data" [value]="item?.unitStatus">{{item?.unitStatus}}</mat-button-toggle>
  </mat-button-toggle-group>
  <mat-button-toggle-group *ngIf="data?.length && selectedTabIndex === 2" [(ngModel)]="product"
    (change)="onSelectProduct($event.value)">
    <mat-button-toggle *ngFor="let item of data" [value]="item?.product">{{item?.product}}</mat-button-toggle>
  </mat-button-toggle-group>
  <mat-tab-group headerPosition="below" mat-align-tabs="center" animationDuration="0ms"
    [selectedIndex]="selectedTabIndex" (selectedIndexChange)="onSelectedTabIndexChange($event)">
    <mat-tab *ngFor="let item of chartList" [label]="item?.title"></mat-tab>
  </mat-tab-group>
</div>
