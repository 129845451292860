import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { BehaviorSubject, Observable } from 'rxjs';

declare const tinycolor: any;

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

const THEME_MODE = 'theme-mode';

@Injectable({
  providedIn: 'root'
})
export class MaterialThemeService {

  private primaryColor = '#005bac';
  private secondaryColor = '#e42020';
  private themeChangeSubject = new BehaviorSubject(ThemeMode.LIGHT);

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    window.addEventListener('storage', (e: StorageEvent) => {
      if (e.key === THEME_MODE) {
        this.setThemeMode(e.newValue as ThemeMode, true);
      }
    });
  }

  /**
   * Current Theme Mode
   *
   * @readonly
   * @private
   * @type {ThemeMode}
   * @memberof MaterialThemeService
   */
  private get currentThemeMode(): ThemeMode {
    return localStorage.getItem(THEME_MODE) as ThemeMode || ThemeMode.LIGHT;
  }

  /**
   * Theme Change Observable
   *
   * @return {*}  {Observable<ThemeMode>}
   * @memberof MaterialThemeService
   */
  public themeChangeObservable$(): Observable<ThemeMode> {
    return this.themeChangeSubject.asObservable();
  }

  /**
   * Set Material Theme
   * @param primaryColor - Primary Color
   * @param secondaryColor - Secondary Color
   */
  public setTheme(primaryColor?: string, secondaryColor?: string) {
    const themeMode = this.currentThemeMode;
    this.setThemeMode(themeMode);
    this.setThemeColor(true, primaryColor || this.primaryColor);
    this.setThemeColor(false, secondaryColor || this.secondaryColor);
  }

  /**
   * Set Theme Color
   * @param isPrimary - IS Primary Color
   * @param colorCode - Color Code
   */
  private setThemeColor(isPrimary: boolean, colorCode?: string) {
    if (isPrimary) {
      document.documentElement.style.setProperty('--primary', colorCode || this.primaryColor);
      document.documentElement.style.setProperty('--primary-color', colorCode || this.primaryColor);
      document.documentElement.style.setProperty('--theme-color', colorCode || this.primaryColor);
    }
    colorCode = colorCode || (isPrimary ? this.primaryColor : this.secondaryColor);
    const palette = isPrimary ? 'primary' : 'secondary';
    const colorPalette = computeColors(colorCode);

    for (const color of colorPalette) {
      const key1 = `--theme-${palette}-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-${palette}-contrast-${color.name}`;
      const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
      document.documentElement.style.setProperty(key1, value1);
      document.documentElement.style.setProperty(key2, value2);
    }
  }

  /**
   *
   *
   * @param {(ThemeMode | string)} mode
   * @memberof MaterialThemeService
   */
  public setThemeMode(mode: ThemeMode, skipStorage?: boolean) {
    if (!this.document.body.classList.contains(`${mode}-theme`)) {
      this.document.body.classList.add(`${mode}-theme`);
      this.themeChangeSubject.next(mode);
      if (!skipStorage) {
        localStorage.setItem(THEME_MODE, mode);
      }
      if (this.document.body.classList.contains(`${mode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK}-theme`))
        this.document.body.classList.remove(`${mode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK}-theme`);
    }
  }

  /**
   * Chart Dark Theme
   *
   * @readonly
   * @private
   * @type {*}
   * @memberof MaterialThemeService
   */
  private get chartDarkTheme(): any {
    return {
      colors: "#2b908f #90ee7e #f45b5b #7798BF #aaeeee #ff0066 #eeaaee #55BF3B #DF5353 #7798BF #aaeeee".split(" "),
      chart: {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
          stops: [
            [0, "#2a2a2b"],
            [1, "#3e3e40"]
          ]
        },
        // style: {
        //   fontFamily: "'Unica One', sans-serif"
        // },
        plotBorderColor: "#606063"
      },
      title: {
        style: {
          color: "#E0E0E3",
          // textTransform: "uppercase",
          fontSize: "20px"
        }
      },
      subtitle: {
        style: {
          color: "#E0E0E3",
          // textTransform: "uppercase"
        }
      },
      xAxis: {
        gridLineColor: "#707073",
        labels: {
          style: {
            color: "#E0E0E3"
          }
        },
        lineColor: "#707073",
        minorGridLineColor: "#505053",
        tickColor: "#707073",
        title: {
          style: {
            color: "#A0A0A3"
          }
        }
      },
      yAxis: {
        gridLineColor: "#707073",
        labels: {
          style: {
            color: "#E0E0E3"
          }
        },
        lineColor: "#707073",
        minorGridLineColor: "#505053",
        tickColor: "#707073",
        tickWidth: 1,
        title: {
          style: {
            color: "#A0A0A3"
          }
        }
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        style: {
          color: "#F0F0F0"
        }
      },
      plotOptions: {
        series: {
          dataLabels: {
            color: "#F0F0F3",
            style: {
              fontSize: "13px"
            }
          },
          marker: {
            lineColor: "#333"
          }
        },
        boxplot: {
          fillColor: "#505053"
        },
        candlestick: {
          lineColor: "white"
        },
        errorbar: {
          color: "white"
        }
      },
      legend: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        itemStyle: {
          color: "#E0E0E3"
        }
      },
      credits: {
        style: {
          color: "#666"
        }
      },
      labels: {
        style: {
          color: "#707073"
        }
      },
      drilldown: {
        activeAxisLabelStyle: {
          color: "#F0F0F3"
        },
        activeDataLabelStyle: {
          color: "#F0F0F3"
        }
      },
      navigation: {
        buttonOptions: {
          symbolStroke: "#DDDDDD",
          theme: {
            fill: "#505053"
          }
        }
      },
      rangeSelector: {
        buttonTheme: {
          fill: "#505053",
          stroke: "#000000",
          style: {
            color: "#CCC"
          },
          states: {
            hover: {
              fill: "#707073",
              stroke: "#000000",
              style: {
                color: "white"
              }
            },
            select: {
              fill: "#000003",
              stroke: "#000000",
              style: {
                color: "white"
              }
            }
          }
        },
        inputBoxBorderColor: "#505053",
        inputStyle: {
          backgroundColor: "#333",
          color: "silver"
        },
        labelStyle: {
          color: "silver"
        }
      },
      navigator: {
        handles: {
          backgroundColor: "#666",
          borderColor: "#AAA"
        },
        outlineColor: "#CCC",
        maskFill: "rgba(255,255,255,0.1)",
        series: {
          color: "#7798BF",
          lineColor: "#A6C7ED"
        },
        xAxis: {
          gridLineColor: "#505053"
        }
      },
      scrollbar: {
        barBackgroundColor: "#808083",
        barBorderColor: "#808083",
        buttonArrowColor: "#CCC",
        buttonBackgroundColor: "#606063",
        buttonBorderColor: "#606063",
        rifleColor: "#FFF",
        trackBackgroundColor: "#404043",
        trackBorderColor: "#404043"
      }
    };
  }

  /**
   * Chart Light Theme
   *
   * @readonly
   * @private
   * @type {*}
   * @memberof MaterialThemeService
   */
  private get chartLightTheme(): any {
    return {
      colors: "#7cb5ec #f7a35c #90ee7e #7798BF #aaeeee #ff0066 #eeaaee #55BF3B #DF5353 #7798BF #aaeeee".split(" "),
      chart: {
        backgroundColor: null,
        // style: {
        //   fontFamily: "Dosis, sans-serif"
        // }
      },
      title: {
        style: {
          fontSize: "16px",
          // fontWeight: "bold",
          // textTransform: "uppercase"
        }
      },
      tooltip: {
        borderWidth: 0,
        backgroundColor: "rgba(219,219,216,0.8)",
        shadow: !1
      },
      legend: {
        backgroundColor: "#F0F0EA",
        itemStyle: {
          // fontWeight: "bold",
          fontSize: "13px"
        }
      },
      xAxis: {
        gridLineWidth: 1,
        labels: {
          style: {
            fontSize: "12px"
          }
        }
      },
      yAxis: {
        minorTickInterval: "auto",
        title: {
          style: {
            // textTransform: "uppercase"
          }
        },
        labels: {
          style: {
            fontSize: "12px"
          }
        }
      },
      plotOptions: {
        candlestick: {
          lineColor: "#404048"
        }
      }
    };
  }

  /**
   * Update Chart Theme
   *
   * @param {Chart} chartRef
   * @param {ThemeMode} [mode]
   * @return {*}  {*}
   * @memberof MaterialThemeService
   */
  public updateChartTheme(chartRef: Chart, mode?: ThemeMode): any {
    mode = mode ?? this.currentThemeMode;
    chartRef.ref$.subscribe((chart: Highcharts.Chart) => {
      if (mode === ThemeMode.DARK) {
        chart.update(this.chartDarkTheme);
      } else {
        chart.update(this.chartLightTheme);
      }
    });
  }


}

function computeColors(hex: string): Color[] {
  return [
    getColorObject(tinycolor(hex).lighten(52), '50'),
    getColorObject(tinycolor(hex).lighten(37), '100'),
    getColorObject(tinycolor(hex).lighten(26), '200'),
    getColorObject(tinycolor(hex).lighten(12), '300'),
    getColorObject(tinycolor(hex).lighten(6), '400'),
    getColorObject(tinycolor(hex), '500'),
    getColorObject(tinycolor(hex).darken(6), '600'),
    getColorObject(tinycolor(hex).darken(12), '700'),
    getColorObject(tinycolor(hex).darken(18), '800'),
    getColorObject(tinycolor(hex).darken(24), '900'),
    getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
    getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
    getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
    getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
  ];
}

function getColorObject(value: any, name: string): Color {
  const c = tinycolor(value);
  return {
    name,
    hex: c.toHexString(),
    darkContrast: c.isLight()
  };
}
