import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject, OnInit } from '@angular/core';

export interface FSDocument extends HTMLDocument {
  // fullscreenElement?: Element;
  // exitFullscreen?: () => void;
  mozFullScreenElement?: Element;
  mozCancelFullScreen?: () => void;
  msFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  webkitFullscreenElement?: Element;
  webkitExitFullscreen?: () => void;
}

export interface FSDocumentElement extends HTMLElement {
  mozRequestFullScreen?: () => void;
  msRequestFullscreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

@Directive({
  selector: '[appFullScreen]',
})
export class FullScreenDirective implements OnInit {
  private _fsDoc: FSDocument;
  private _fsDocEl!: FSDocumentElement;
  public isFullscreen: boolean = false;

  constructor(@Inject(DOCUMENT) private _document: Document) {
    this._fsDoc = _document as FSDocument;
  }

  /**
   * On Init Method
   *
   * @memberof FullScreenDirective
   */
  ngOnInit(): void {
    this._fsDocEl = document.documentElement as FSDocumentElement;
  }

  /**
   * Toggle Full Screen
   *
   * @private
   * @memberof FullScreenDirective
   */
  @HostListener('click')
  private toggleFullscreen(): void {
    // Check if the fullscreen is open
    this.isFullscreen = this._getBrowserFullscreenElement() !== null;

    // Toggle the fullscreen
    if (this.isFullscreen) {
      this._closeFullscreen();
    } else {
      this._openFullscreen();
    }
  }

  /**
   * Get Full Screen Element
   *
   * @private
   * @return {*}  {Element}
   * @memberof FullScreenDirective
   */
  private _getBrowserFullscreenElement(): Element {
    //  if ( typeof this._fsDoc.fullscreenElement !== 'undefined' )
    //  {
    //      return this._fsDoc.fullscreenElement;
    //  }

    if (typeof this._fsDoc.mozFullScreenElement !== 'undefined') {
      return this._fsDoc.mozFullScreenElement;
    }

    if (typeof this._fsDoc.msFullscreenElement !== 'undefined') {
      return this._fsDoc.msFullscreenElement;
    }

    if (typeof this._fsDoc.webkitFullscreenElement !== 'undefined') {
      return this._fsDoc.webkitFullscreenElement;
    }

    throw new Error('Fullscreen mode is not supported by this browser');
  }

  /**
   * Open Full Screen
   *
   * @private
   * @return {*}  {void}
   * @memberof FullScreenDirective
   */
  private _openFullscreen(): void {
    if (this._fsDocEl.requestFullscreen) {
      this._fsDocEl.requestFullscreen();
      return;
    }

    // Firefox
    if (this._fsDocEl.mozRequestFullScreen) {
      this._fsDocEl.mozRequestFullScreen();
      return;
    }

    // Chrome, Safari and Opera
    if (this._fsDocEl.webkitRequestFullscreen) {
      this._fsDocEl.webkitRequestFullscreen();
      return;
    }

    // IE/Edge
    if (this._fsDocEl.msRequestFullscreen) {
      this._fsDocEl.msRequestFullscreen();
      return;
    }
  }

  /**
   * Close Full Screen
   *
   * @private
   * @return {*}  {void}
   * @memberof FullScreenDirective
   */
  private _closeFullscreen(): void {
    if (this._fsDoc.exitFullscreen) {
      this._fsDoc.exitFullscreen();
      return;
    }

    // Firefox
    if (this._fsDoc.mozCancelFullScreen) {
      this._fsDoc.mozCancelFullScreen();
      return;
    }

    // Chrome, Safari and Opera
    if (this._fsDoc.webkitExitFullscreen) {
      this._fsDoc.webkitExitFullscreen();
      return;
    }

    // IE/Edge
    else if (this._fsDoc.msExitFullscreen) {
      this._fsDoc.msExitFullscreen();
      return;
    }
  }
}
