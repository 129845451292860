import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'editable-grid-row-action',
  templateUrl: './editable-grid-row-action.component.html',
  styleUrls: ['./editable-grid-row-action.component.scss']
})
export class EditableGridRowActionComponent {

  @Input('index') rowIndex: number | undefined;
  @Input('editedIndex') editedRowIndex: number | undefined;
  @Output('onEdit') onEdit: EventEmitter<number> = new EventEmitter<number>();
  @Output('onRemove') onRemove: EventEmitter<number> = new EventEmitter<number>();
  @Output('onSave') onSave: EventEmitter<number> = new EventEmitter<number>();
  @Output('onCancel') onCancel: EventEmitter<number> = new EventEmitter<number>();
}
