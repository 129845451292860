import { Component } from '@angular/core';
import { MaterialThemeService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private themeService: MaterialThemeService
  ) {
    this.themeService.setTheme();
  }

}
