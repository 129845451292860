/**
 * Set Local Storage
 * @param key 
 * @param data 
 */
export function setLocalstorage(key: string, data: string) {
    localStorage.setItem(key, data);
}

/**
 * Get Local Storage
 * @param key 
 */
export function getLocalstorage(key: string) {
    return localStorage.getItem(key);
}

/**
 * Remove Local Storage
 * @param key 
 */
export function removeLocalstorage(key: string) {
    localStorage.removeItem(key);
}