import { Component, OnInit } from '@angular/core';
import { ApiAppResponse, ApiService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { MaterialThemeService, ThemeMode } from 'src/app/shared';

import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import itemSeries from 'highcharts/modules/item-series';
import drilldown from 'highcharts/modules/drilldown';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
itemSeries(Highcharts);
drilldown(Highcharts);
exporting(Highcharts);
exportData(Highcharts);

@Component({
  selector: 'app-admin-item-chart',
  templateUrl: './admin-item-chart.component.html',
  styleUrls: ['./admin-item-chart.component.scss'],
})
export class AdminItemChartComponent implements OnInit {
  public chartList: any[] = [
    {
      title: 'Product Wise Ticket Count',
      subtitle: 'Ticket Status',
      method: 'Product_Wise_Ticket_Status',
    },
    {
      title: 'I-Base Unit Status Wise Units Count',
      subtitle: 'Unit Status',
      method: 'I_Base_Unit_Status',
    },
    {
      title: 'I-Base Product Wise Units Count',
      subtitle: 'Product',
      method: 'I_Base_Product_Status',
    },
  ];
  public selectedTabIndex: number = 0;
  public ticketStatus!: string;
  public product!: string;
  public unitStatus!: string;

  public data: any[] = [];
  public chart = new Chart({});
  private options: any = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'item',
    },
    credits: {
      enabled: true,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      pie: {
        center: ['50%', '88%'],
        size: '170%',
        startAngle: -100,
        endAngle: 100,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '{point.name} - {point.y}',
        },
        showInLegend: true,
      },
      item: {
        center: ['50%', '88%'],
        size: '170%',
        startAngle: -100,
        endAngle: 100,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '{point.name} - {point.y}',
        },
        showInLegend: true,
      },
    },
    series: [],
    drilldown: {
      series: [],
    },
    legend: {
      labelFormat: '{name} <span style="opacity: 0.4">{y}</span>',
    },
  };
  private themeChangeSubscription!: Subscription;
  constructor(
    private apiService: ApiService,
    private themeService: MaterialThemeService
  ) {
    this.themeChangeSubscription = this.themeService
      .themeChangeObservable$()
      .subscribe((mode: ThemeMode) => {
        this.themeService.updateChartTheme(this.chart, mode);
      });
  }

  /**
   * On Init Method
   *
   * @memberof ProductWiseTicketChartComponent
   */
  ngOnInit(): void {
    this.chart = new Chart(this.options);
    this.themeService.updateChartTheme(this.chart);
    this.onSelectedTabIndexChange(0);
  }

  /**
   * On Destroy Method
   *
   * @memberof MkaWiseTicketChartComponent
   */
  ngOnDestroy() {
    if (this.themeChangeSubscription) {
      this.themeChangeSubscription.unsubscribe();
    }
  }

  /**
   * On Selected Tab Index Change
   *
   * @param {number} index
   * @memberof AdminItemChartComponent
   */
  onSelectedTabIndexChange(index: number) {
    this.selectedTabIndex = index;
    switch (index) {
      case 0:
        this.productWiseTicketCount();
        break;

      case 1:
        this.iBaseUnitStatusWiseUnitsCount();
        break;

      case 2:
        this.iBaseProductWiseUnitsCount();
        break;

      default:
        break;
    }
  }

  /**
   * Product Wise Ticket Count
   *
   * @memberof AdminItemChartComponent
   */
  productWiseTicketCount() {
    this.apiService
      .adminChart(this.chartList[this.selectedTabIndex].method)
      .subscribe((res: any) => {
        if (res.appresponse === ApiAppResponse.SUCCESS) {
          this.data = res.result || [];
          if (this.data?.length) {
            this.onSelectStatus(this.data[0]?.status);
          }
        }
      });
  }

  /**
   * On Select Status
   *
   * @param {string} status
   * @memberof ProductWiseTicketChartComponent
   */
  onSelectStatus(status: string) {
    this.ticketStatus = status;
    const item = this.data.find((s: any) => s.status === status);

    if (!item) {
      return;
    }

    const series: any = {
      name: item?.product,
      colorByPoint: true,
      data: [],
    };

    const options = Object.assign({}, this.options);
    options.title.text = this.chartList[this.selectedTabIndex].title;
    options.subtitle = {
      text: status,
    };
    series.data = [];
    item.data.forEach((c: any) => {
      series.data.push({ name: c.product, y: Number(c.count) });
    });
    this.chart = new Chart(options);
    this.themeService.updateChartTheme(this.chart);
    this.chart.removeSeries(0);
    this.chart.addSeries(series, true, true);
  }

  /**
   * IBase Product Wise Units Count
   *
   * @memberof AdminItemChartComponent
   */
  iBaseProductWiseUnitsCount() {
    this.apiService
      .adminChart(this.chartList[this.selectedTabIndex].method)
      .subscribe((res: any) => {
        if (res.appresponse === ApiAppResponse.SUCCESS) {
          this.data = res.result || [];
          if (this.data?.length) {
            this.onSelectProduct(this.data[0]?.product);
          }
        }
      });
  }

  /**
   * On Select Product
   *
   * @param {string} product
   * @return {*}
   * @memberof AdminItemChartComponent
   */
  onSelectProduct(product: string) {
    this.product = product;
    const item = (this.data || []).find((o: any) => o.product === product);

    if (!item) {
      return;
    }

    const series: any = {
      name: item?.product,
      colorByPoint: true,
      data: [],
    };
    const seriesData: any[] = [];
    const drilldownSeries: any[] = [];
    const options = Object.assign({}, this.options);
    options.title.text = this.chartList[this.selectedTabIndex].title;
    options.subtitle = {
      text: product,
    };
    (item?.data || []).forEach((mka: any) => {
      seriesData.push({
        name: mka.mka,
        y: mka.count,
        drilldown: mka?.data?.length ? mka.mka : null,
      });
      if (mka?.data?.length) {
        const drilldownMkaSeriesData: any = {
          name: mka.mka,
          id: mka.mka,
          data: [],
        };
        (mka?.data || []).forEach((zonal: any) => {
          drilldownMkaSeriesData.data.push({
            name: zonal.zonal,
            y: zonal.count,
            drilldown: zonal?.data?.length
              ? `${mka?.mka} - ${zonal?.zonal}`
              : null,
          });
          const drilldownZonalSeriesData: any = {
            name: zonal.zonal,
            id: `${mka?.mka} - ${zonal?.zonal}`,
            data: [],
          };
          (zonal?.data || []).forEach((regional: any) => {
            drilldownZonalSeriesData.data.push({
              name: regional.regional,
              y: regional.count,
            });
          });
          drilldownSeries.push(drilldownZonalSeriesData);
        });
        drilldownSeries.push(drilldownMkaSeriesData);
      }
    });
    series.data = seriesData;
    options.series = [series];
    options.drilldown.series = drilldownSeries;
    this.chart = new Chart(options);
    this.themeService.updateChartTheme(this.chart);
  }

  /**
   * IBase Unit Status Wise Units Count
   *
   * @memberof AdminItemChartComponent
   */
  iBaseUnitStatusWiseUnitsCount() {
    this.apiService
      .adminChart(this.chartList[this.selectedTabIndex].method)
      .subscribe((res: any) => {
        if (res.appresponse === ApiAppResponse.SUCCESS) {
          this.data = res.result || [];
          if (this.data?.length) {
            this.onSelectUnitStatus(this.data[0]?.unitStatus);
          }
        }
      });
  }

  /**
   * On Select Unit Status
   *
   * @param {string} unitStatus
   * @return {*}
   * @memberof AdminItemChartComponent
   */
  onSelectUnitStatus(unitStatus: string) {
    this.unitStatus = unitStatus;
    const item = (this.data || []).find(
      (o: any) => o.unitStatus === unitStatus
    );

    if (!item) {
      return;
    }

    const series: any = {
      name: item?.unitStatus,
      colorByPoint: true,
      data: [],
    };
    const seriesData: any[] = [];
    const drilldownSeries: any[] = [];
    const options = Object.assign({}, this.options);
    options.title.text = this.chartList[this.selectedTabIndex].title;
    options.subtitle = {
      text: unitStatus,
    };
    (item?.data || []).forEach((mka: any) => {
      seriesData.push({
        name: mka.mka,
        y: mka.count,
        drilldown: mka?.data?.length ? mka.mka : null,
      });
      if (mka?.data?.length) {
        const drilldownMkaSeriesData: any = {
          name: mka.mka,
          id: mka.mka,
          data: [],
        };
        (mka?.data || []).forEach((zonal: any) => {
          drilldownMkaSeriesData.data.push({
            name: zonal.zonal,
            y: zonal.count,
            drilldown: zonal?.data?.length
              ? `${mka?.mka} - ${zonal?.zonal}`
              : null,
          });
          const drilldownZonalSeriesData: any = {
            name: zonal.zonal,
            id: `${mka?.mka} - ${zonal?.zonal}`,
            data: [],
          };
          (zonal?.data || []).forEach((regional: any) => {
            drilldownZonalSeriesData.data.push({
              name: regional.regional,
              y: regional.count,
            });
          });
          drilldownSeries.push(drilldownZonalSeriesData);
        });
        drilldownSeries.push(drilldownMkaSeriesData);
      }
    });
    series.data = seriesData;
    options.series = [series];
    options.drilldown.series = drilldownSeries;
    this.chart = new Chart(options);
    this.themeService.updateChartTheme(this.chart);
  }
}
