import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  @Input() totalStars = 5;

  @Output() onRate: EventEmitter<number> = new EventEmitter<number>();

  private selectedValue = 0;

  constructor() {}

  ngOnInit(): void {}

  get stars() {
    return Array.from(Array(this.totalStars).keys());
  }

  /**
   * Rate Emit
   *
   * @param {number} value
   * @memberof StarRatingComponent
   */
  rate(value: number) {
    this.selectedValue = value;
    this.onRate.emit(value);
  }

  get value() {
    return this.selectedValue;
  }
}
